enum ProjectColor {
  gray,
  grey,
  greyPurple,
  greyBlack,
  light,
  white,
  orange,
  purple,
  purpleWhite,
  green,
}

export default ProjectColor;
