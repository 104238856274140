enum Technology {
  JavaScript,
  TypeScript,
  NodeJS,
  Angular,
  React,
  Unity,
  GraphQL,
  TypeGraphQL,
  jQuery,
  ExpressJS,
  NestJS,
  AngularJS,
  HTML5,
  SCSS,
  CSS,
  CSharp,
  ".Net Core",
  Java,
  PHP,
  Cypress,
  WordPress,
  ScrollMagic,
  Solidity,
  WebSockets,
  Web3JS,
  SQLite,
  MySQL,
  MongoDB,
  Firebase,
  PostgreSQL,
  Android,
  Kotlin,
  Swift,
  DevExpress,
  WCF,
  Ionic,
  Docker,
  Webpack,
  Azure,
  Git,
  SVN,
}

export default Technology;
