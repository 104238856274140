import { TileImagePosition } from "components/tile";
import ProjectColor from "enums/projectColor";
import Technology from "enums/technology";

export const skills = Object.keys(Technology).filter(
  (value) => Number.isNaN(Number(value)) === true,
) as (keyof typeof Technology)[];

export const projects = [
  {
    title: "Fizja",
    supTitle: "KIF",
    date: "01.2022 - present",
    description: "The courses platform for physiotherapists",
    role: "Senior Frontend Developer",
    image: "/images/Fizja.png",
    imagePosition: TileImagePosition.margin,
    tags: [
      Technology.TypeScript,
      Technology.React,
      Technology.NodeJS,
      Technology.HTML5,
      Technology.SCSS,
      Technology.CSS,
      Technology.Git,
      Technology.Azure,
    ],
    color: ProjectColor.grey,
  },
  {
    title: "Playground",
    supTitle: "Gorrion",
    date: "10.2020 - present as support",
    description: "An application with various games for learning English for the younger kids",
    role: "Fullstack Developer",
    image: "/images/Playground.png",
    imagePosition: TileImagePosition.overflow,
    tags: [
      Technology.Unity,
      Technology.TypeScript,
      Technology.CSharp,
      Technology.NodeJS,
      Technology.NestJS,
      Technology.MongoDB,
      Technology.HTML5,
      Technology.SCSS,
      Technology.CSS,
      Technology.Webpack,
      Technology.Docker,
      Technology.Git,
    ],
    color: ProjectColor.green,
    url: "https://placzabaw.teddyeddie.pl",
  },
  {
    title: "SavvyQuest",
    supTitle: "Gorrion",
    date: "03.2019 - present as support",
    description: "An application with various games for learning English for the older kids",
    role: "Fullstack Developer",
    image: "/images/SavvyQuest.png",
    imagePosition: TileImagePosition.overflow,
    tags: [
      Technology.Unity,
      Technology.TypeScript,
      Technology.CSharp,
      Technology.Angular,
      Technology.NodeJS,
      Technology.ExpressJS,
      Technology.GraphQL,
      Technology.TypeGraphQL,
      Technology.MongoDB,
      Technology.HTML5,
      Technology.SCSS,
      Technology.CSS,
      Technology.Webpack,
      Technology.Docker,
      Technology.Git,
    ],
    color: ProjectColor.orange,
    url: "https://sq.savvyed.pl",
  },
  {
    title: "vdpSurvey",
    supTitle: "NEATsoft",
    date: "03.2022 - 04.2022",
    description: "The questionnaire for knowledge about law",
    role: "Frontend Developer",
    tags: [
      Technology.React,
      Technology.WebSockets,
      Technology.Cypress,
      Technology.HTML5,
      Technology.SCSS,
      Technology.CSS,
      Technology.Docker,
      Technology.Git,
    ],
    color: ProjectColor.greyPurple,
  },
  {
    title: "vdpResearch",
    supTitle: "NEATsoft",
    date: "02.2022 - 03.2022",
    description: "The app for online visits via website",
    role: "Fullstack Developer",
    tags: [
      Technology[".Net Core"],
      Technology.CSharp,
      Technology.Angular,
      Technology.WebSockets,
      Technology.HTML5,
      Technology.SCSS,
      Technology.CSS,
      Technology.Docker,
      Technology.Git,
    ],
    color: ProjectColor.light,
  },
  {
    title: "Landing",
    supTitle: "Kontakt.io",
    date: "08.2021 - 09.2021",
    description: "An Asset Tag 2 page for Kontakt.io",
    role: "Fullstack Developer",
    image: "/images/AssetTag2.png",
    imagePosition: TileImagePosition.margintop,
    tags: [
      Technology.PHP,
      Technology.WordPress,
      Technology.ScrollMagic,
      Technology.HTML5,
      Technology.SCSS,
      Technology.CSS,
    ],
    color: ProjectColor.white,
    url: "https://kontakt.io/asset-tag-2-2",
  },
  {
    title: "Landing",
    supTitle: "Kontakt.io",
    date: "08.2021 - 09.2021",
    description: "A Nano Tag page for Kontakt.io",
    role: "Fullstack Developer",
    image: "/images/NanoTag.png",
    imagePosition: TileImagePosition.margintop,
    tags: [
      Technology.PHP,
      Technology.WordPress,
      Technology.ScrollMagic,
      Technology.HTML5,
      Technology.SCSS,
      Technology.CSS,
    ],
    color: ProjectColor.purpleWhite,
    url: "https://kontakt.io/nano-tag",
  },
  {
    title: "Landing",
    supTitle: "Kontakt.io",
    date: "08.2021 - 09.2021",
    description: "A Anchor Beacon 2 page for Kontakt.io",
    role: "Fullstack Developer",
    image: "/images/AnchorBeacon2.png",
    imagePosition: TileImagePosition.margintop,
    tags: [
      Technology.PHP,
      Technology.WordPress,
      Technology.ScrollMagic,
      Technology.HTML5,
      Technology.SCSS,
      Technology.CSS,
    ],
    color: ProjectColor.grey,
    url: "https://kontakt.io/anchor-beacon-2",
  },
  {
    title: "NFT Cards",
    date: "11.2021 - 12.2021",
    description: "A NFT project",
    role: "Fullstack Developer",
    image: "/images/NFT.png",
    imagePosition: TileImagePosition.inside,
    tags: [
      Technology.TypeScript,
      Technology.Angular,
      Technology.NodeJS,
      Technology.Docker,
      Technology.NestJS,
      Technology.Solidity,
      Technology.Web3JS,
      Technology.Firebase,
      Technology.SQLite,
      Technology.MongoDB,
      Technology.HTML5,
      Technology.SCSS,
      Technology.CSS,
    ],
    color: ProjectColor.orange,
  },
  {
    title: "Landing",
    supTitle: "Kontakt.io",
    date: "01.2021 - 02.2021",
    description: "A Smart Badge page for Kontakt.io",
    role: "Fullstack Developer",
    image: "/images/SmartBadge.png",
    imagePosition: TileImagePosition.overflow,
    tags: [
      Technology.PHP,
      Technology.WordPress,
      Technology.ScrollMagic,
      Technology.HTML5,
      Technology.SCSS,
      Technology.CSS,
    ],
    color: ProjectColor.green,
    url: "https://kontakt.io/smart-badge",
  },
  {
    title: "Landing",
    supTitle: "Kontakt.io",
    date: "01.2021 - 02.2021",
    description: "A Portal Beam page for Kontakt.io",
    role: "Fullstack Developer",
    image: "/images/PortalBeam.png",
    imagePosition: TileImagePosition.overflow,
    tags: [
      Technology.PHP,
      Technology.WordPress,
      Technology.ScrollMagic,
      Technology.HTML5,
      Technology.SCSS,
      Technology.CSS,
    ],
    color: ProjectColor.greyPurple,
    url: "https://kontakt.io/portal-beam",
  },
  {
    title: "Website",
    supTitle: "TTL",
    date: "11.2020 - 12.2020",
    description: "A game developer studio site",
    role: "Fullstack Developer",
    image: "/images/TheThreeLegends.gif",
    imagePosition: TileImagePosition.small,
    tags: [
      Technology.JavaScript,
      Technology.React,
      Technology.HTML5,
      Technology.SCSS,
      Technology.CSS,
      Technology.Git,
    ],
    color: ProjectColor.white,
    url: "https://www.thethreelegends.com",
  },
  {
    title: "Jumponce",
    supTitle: "TTL",
    date: "08.2020 - 10.2020",
    description: "A game developer studio site",
    role: "Mobile Developer",
    image: "/images/Jumponce.png",
    imagePosition: TileImagePosition.overflow,
    tags: [
      Technology.Unity,
      Technology.Firebase,
      Technology.SQLite,
      Technology.CSharp,
      Technology.Git,
    ],
    color: ProjectColor.white,
    androidUrl: "https://play.google.com/store/apps/details?id=com.thethreelegends.Jumponce",
    iosUrl: "null",
  },
  {
    title: "Shredded",
    supTitle: "Gorrion",
    date: "08.2020 - 10.2020",
    description: "A fitness app",
    role: "Mobile Developer",
    tags: [
      Technology.Android,
      Technology.Kotlin,
      Technology.Swift,
      Technology.Firebase,
      Technology.PostgreSQL,
      Technology.Git,
    ],
    color: ProjectColor.white,
  },
  {
    title: "Geohist",
    supTitle: "Gorrion",
    date: "05.2020 - 03.2022",
    description: "A web application displaying various information on the map",
    role: "Fullstack Developer",
    tags: [
      Technology.JavaScript,
      Technology.React,
      Technology.NodeJS,
      Technology.ExpressJS,
      Technology.HTML5,
      Technology.SCSS,
      Technology.CSS,
      Technology.Docker,
      Technology.MySQL,
      Technology.Git,
    ],
    color: ProjectColor.light,
    url: "https://geohist.ibrbs.pl",
  },
  {
    title: "Tubądzin",
    supTitle: "Gorrion",
    date: "09.2019 - 02.2022",
    description: "A platform for displaying a bathroom visualization based on filters",
    role: "Fullstack Developer",
    image: "/images/Tubądzin.png",
    imagePosition: TileImagePosition.inside,
    tags: [
      Technology.TypeScript,
      Technology.JavaScript,
      Technology.React,
      Technology.NodeJS,
      Technology.ExpressJS,
      Technology.MongoDB,
      Technology.HTML5,
      Technology.SCSS,
      Technology.CSS,
      Technology.Webpack,
      Technology.Docker,
      Technology.Git,
    ],
    color: ProjectColor.greyPurple,
    url: "https://wirtualnalazienka.tubadzin.pl",
  },
  {
    title: "Loughs",
    supTitle: "Gorrion",
    date: "02.2019 - 09.2021",
    description: "A platform for buying fishing licenses",
    role: "Fullstack Developer",
    tags: [
      Technology.TypeScript,
      Technology.React,
      Technology.NodeJS,
      Technology.ExpressJS,
      Technology.GraphQL,
      Technology.TypeGraphQL,
      Technology.MongoDB,
      Technology.HTML5,
      Technology.SCSS,
      Technology.CSS,
      Technology.Webpack,
      Technology.Docker,
      Technology.Git,
    ],
    color: ProjectColor.gray,
    url: "https://elicence.loughs-agency.org",
  },
  {
    title: "Game",
    supTitle: "Gorrion",
    date: "04.2020 - 05.2020",
    description: "A mobile game showing the company's process",
    role: "Fullstack Developer",
    image: "/images/GorrionGame.png",
    imagePosition: TileImagePosition.overflow,
    tags: [Technology.Unity, Technology.Firebase, Technology.CSharp, Technology.Git],
    color: ProjectColor.greyPurple,
    androidUrl: "https://play.google.com/store/apps/details?id=io.gorrion.roadtosuccess",
    iosUrl: "https://apps.apple.com/za/app/road-to-success/id1514592622",
  },
  {
    title: "LokoParry",
    supTitle: "Gorrion",
    date: "11.2018 - 03.2019",
    description: "A social app",
    role: "Fullstack Developer",
    tags: [
      Technology.Ionic,
      Technology.JavaScript,
      Technology.HTML5,
      Technology.SCSS,
      Technology.CSS,
      Technology.SQLite,
      Technology.MySQL,
      Technology.Android,
      Technology.Swift,
      Technology.Git,
    ],
    color: ProjectColor.white,
  },
  {
    title: "WebSimax",
    supTitle: "Gorrion",
    date: "06.2016 - 03.2019",
    description: "A large project to manage locations, devices and meters for several companies.",
    role: "Fullstack Developer",
    tags: [
      Technology.AngularJS,
      Technology.JavaScript,
      Technology.TypeScript,
      Technology.jQuery,
      Technology.CSharp,
      Technology[".Net Core"],
      Technology.DevExpress,
      Technology.WCF,
      Technology.HTML5,
      Technology.SCSS,
      Technology.CSS,
      Technology.Git,
    ],
    color: ProjectColor.purpleWhite,
  },
  {
    title: "Eduś",
    supTitle: "Gorrion",
    date: "11.2015 - 05.2016",
    description: "Educational platform",
    role: "Fullstack Developer",
    image: "/images/Eduś.png",
    imagePosition: TileImagePosition.overflow,
    tags: [
      Technology.PHP,
      Technology.JavaScript,
      Technology.jQuery,
      Technology.HTML5,
      Technology.MySQL,
      Technology.CSS,
      Technology.Git,
    ],
    color: ProjectColor.green,
    url: "https://edus.ibrbs.pl",
  },
  {
    title: "Absolut",
    supTitle: "Gorrion",
    date: "08.2015 - 11.2015",
    description: "A platform for displaying drinks based on filters",
    role: "Fullstack Developer",
    image: "/images/Absolut.png",
    imagePosition: TileImagePosition.overflow,
    tags: [
      Technology.PHP,
      Technology.JavaScript,
      Technology.AngularJS,
      Technology.jQuery,
      Technology.HTML5,
      Technology.MySQL,
      Technology.CSS,
      Technology.Git,
    ],
    color: ProjectColor.gray,
  },
  {
    title: "Tauron",
    supTitle: "Gorrion",
    date: "03.2015 - 07.2015",
    description: "A page for the annual report",
    role: "Fullstack Developer",
    image: "/images/Tauron.png",
    imagePosition: TileImagePosition.inside,
    tags: [
      Technology.PHP,
      Technology.WordPress,
      Technology.JavaScript,
      Technology.jQuery,
      Technology.HTML5,
      Technology.MySQL,
      Technology.CSS,
      Technology.Git,
    ],
    color: ProjectColor.greyBlack,
  },
  {
    title: "Asus",
    supTitle: "Gorrion",
    date: "10.2014 - 12.2014",
    description: "A contest page for T100 laptop campaigns",
    role: "Fullstack Developer",
    image: "/images/Asus.png",
    imagePosition: TileImagePosition.inside,
    tags: [
      Technology.PHP,
      Technology.JavaScript,
      Technology.jQuery,
      Technology.HTML5,
      Technology.CSS,
      Technology.Git,
    ],
    color: ProjectColor.grey,
  },
  {
    title: "Przejrzyj chłoniaka",
    supTitle: "Gorrion",
    date: "06.2014 - 10.2014",
    description: 'A website for the "mam haka na raka" campaign',
    role: "Fullstack Developer",
    tags: [
      Technology.PHP,
      Technology.JavaScript,
      Technology.jQuery,
      Technology.HTML5,
      Technology.CSS,
      Technology.Git,
    ],
    color: ProjectColor.white,
  },
  {
    title: "Iced Fruit",
    supTitle: "Gorrion",
    date: "05.2014 - 06.2014",
    description: "An application for McDonald's Iced Fruit Smoothies campaign",
    role: "Fullstack Developer",
    image: "/images/Iced Fruit.png",
    imagePosition: TileImagePosition.overflow,
    tags: [
      Technology.PHP,
      Technology.AngularJS,
      Technology.JavaScript,
      Technology.jQuery,
      Technology.HTML5,
      Technology.CSS,
      Technology.Git,
    ],
    color: ProjectColor.purpleWhite,
  },
  {
    title: "AmCham",
    supTitle: "Gorrion",
    date: "04.2014 - 05.2014",
    description: "A CRM for AmCham",
    role: "Fullstack Developer",
    tags: [
      Technology.PHP,
      Technology.WordPress,
      Technology.JavaScript,
      Technology.jQuery,
      Technology.HTML5,
      Technology.MySQL,
      Technology.CSS,
      Technology.Git,
    ],
    color: ProjectColor.light,
  },
  {
    title: "Wedel",
    supTitle: "Gorrion",
    date: "12.2013 - 01.2014",
    description: 'A small contest application for "Czekoladowy weekend" event',
    role: "Fullstack Developer",
    image: "/images/Wedel.png",
    imagePosition: TileImagePosition.inside,
    tags: [
      Technology.PHP,
      Technology.JavaScript,
      Technology.jQuery,
      Technology.HTML5,
      Technology.MySQL,
      Technology.CSS,
      Technology.Git,
    ],
    color: ProjectColor.greyPurple,
  },
  {
    title: "McDonald's",
    supTitle: "Gorrion",
    date: "10.2013 - 12.2013",
    description: "An internal platform for employee management",
    role: "Fullstack Developer",
    image: "/images/McDonald's - employee.png",
    imagePosition: TileImagePosition.overflow,
    tags: [
      Technology.PHP,
      Technology.JavaScript,
      Technology.AngularJS,
      Technology.jQuery,
      Technology.MySQL,
      Technology.HTML5,
      Technology.CSS,
      Technology.Git,
    ],
    color: ProjectColor.white,
  },
  {
    title: "Geoportal",
    supTitle: "Geosolution",
    date: "08.2012 - 10.2013",
    description: "A large application for maps in Silesia",
    role: "Fullstack Developer",
    tags: [
      Technology.JavaScript,
      Technology.Java,
      Technology.jQuery,
      Technology.PostgreSQL,
      Technology.HTML5,
      Technology.CSS,
      Technology.SVN,
    ],
    color: ProjectColor.greyBlack,
  },
];
